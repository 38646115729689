.swiper-holder,
.swiper-holder-3 {

  position: relative;

  .swiper-slide {

    height: auto;
  }
}

.swiper-button-prev,
.swiper-button-next {  

  &:after {

    color: $gray;
    font-size: rem(25px) !important;
  }
  
}

@include media-breakpoint-down(md) {

  .swiper-button-prev {  
  
    left: -10px !important;
  }
  
  .swiper-button-next {  
  
    right: -10px !important;
  }
}

.arrow-nav {

  position: absolute; 
  width: calc(50% - 10px);
  right: 0;
  bottom: 0;
  height: 51px;

  &.start {

    right: auto;
    left: 0;
  }

  .arrow-nav-inner {

    position: relative;
    display: flex;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {

    position: relative;
    bottom: 0 !important;
    left: auto;
    right: auto;
    margin: 0 10px;
    width: 51px;
    height: 51px;

    &:after {

      display: none;
    }

    &:hover {

      background-position: 0 100% !important;
    }
  }

  .swiper-button-prev {

    background: url('../images/home/prev.jpg') no-repeat 0 0;

  }

  .swiper-button-next {

    background: url('../images/home/next.jpg') no-repeat 0 0;

  }

  &.arrow-nav2 {

    @include media-breakpoint-down(lg) {

      width: 100%;
  
      .arrow-nav-inner {
  
        justify-content: center;
      }
    }
  }

  @include media-breakpoint-down(md) {

    width: 100%;

    .arrow-nav-inner {

      justify-content: center;
    }
  }

}

.swiper-button-prev {

  left: -40px;
}

.swiper-button-next {

  right: -40px;
  
}

.swiper-holder-2 {

  .swiper-pagination { 

    bottom: -50px !important;
  }  
}


.swiper-pagination {

  bottom: -40px !important;

  .swiper-pagination-bullet {

    position: relative;
    padding: 4px;
    margin: 0 4px !important;
    width: 12px;
    height: 12px;    
    background: rgba($black, 0.8);

    &.swiper-pagination-bullet-active {

      background: $black;
    }
  }

  &.start {

    text-align: left;
  }
}

.item-bottom {

  position: relative;
  display: flex;
  justify-content: space-between;

  .swiper-pagination {

    position: initial;
    top: auto;
    bottom: auto;
  }
}


.swiper-top,
.swiper-thumb {

  touch-action: pan-y;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;  
}

.swiper-top {

  border-radius: 5px;
  overflow: hidden;

  .swiper-button-next,
  .swiper-button-prev {

    font-size: rem(30px);

    &:after {

      display: none;
    }

    &:hover {

      opacity: 1;
    }
  }

  .swiper-button-prev {

    top: 20px;
    left: 0;
    width: 80px;
    height: 100%;
    opacity: 0.7;
    @include linear-gradient(to right, rgba($blue, 0.7), transparent);
  }

  .swiper-button-next {

    top: 20px;
    right: 0;
    width: 80px;
    height: 100%;
    opacity: 0.7;
    @include linear-gradient(to left, rgba($blue, 0.7), transparent);
  }
}

@include media-breakpoint-down(xxl) {

  .swiper-holder-2 {

    padding-left: 40px;
    padding-right: 40px;
  }

  .swiper-button-prev {

    left: 0;
  }
  
  .swiper-button-next {
  
    right: 0;
    
  }
}
