.exhibits {

  display: flex;

  h3 {

    text-transform: uppercase;
    flex-shrink: 0;
    width: 72px;
  }

  @include media-breakpoint-down(lg) {

    flex-direction: column;

    .vertical-lr {

      writing-mode: initial;
      margin-bottom: 30px;
    }
  }

}

.container-md-left {

  padding-right: 3rem;

  @include media-breakpoint-down(lg) {

    padding-right: 0;
  }
}

.exhibit-lists {

  margin-left: 40px;
  margin-bottom: 50px;

  .ex-list {

    position: relative;    
    margin-bottom: 20px;

    a {
      display: flex;      
      color: $black;
    }
  }

  h4 {

    font-size: rem(18px);
    margin-bottom: 0;
  }

  .ex-content {

    text-transform: uppercase;
    margin-left: 20px;

    span {

      font-size: rem(13px);      
    }
  }

  @include media-breakpoint-down(lg) {

    margin-left: 0;
  }


  @include media-breakpoint-down(sm) {

    .ex-list {
  
      a {
        flex-direction: column;
      }

      .ex-content {

        margin-left: 0;
        padding-top: 20px;
      }
    }
  }
}


.container-sticky {

  display: flex;
  flex-direction: column;
  min-width: 45%;
  // height: 100vh;
  padding-top: 190px;

  .bottom-bg {

    // position: fixed;
    // bottom: 0;
    // margin-top: auto;

    position: sticky;
    top: calc(100% - 140px);
    margin-bottom: -60px;

    img {

      height: 140px;
      width: 100%;
      max-width: initial;
      object-fit: cover;
      object-position: center;
    }
  }

  @include media-breakpoint-down(lg) {

    border-left: 0;
    height: auto;
    min-width: 100%;
    padding-top: 30px;

    .bottom-bg {

      display: none;
    }
  }
}

.ex-img {

  flex-shrink: 0;
}

.content-sticky {

  z-index: 3;
}