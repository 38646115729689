h1 {


}

h2 {

  font-family: $RocGrotesk;
  font-size: rem(30px);
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;

  @include media-breakpoint-down(md) {

    font-size: rem(25px);
  }
}

h3 {

  font-family: $RocGrotesk;
  font-size: rem(30px);
  color: $orange;
  font-weight: 700;
}

h4 {

  font-family: $RocGrotesk;
  font-size: rem(16px);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.author-label {

  display: inline-block;
  text-transform: uppercase;
}

small {

  display: inline-block;
  font-size: 75%;
  line-height: 1.8;
  color: $gray;
}