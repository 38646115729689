// include all imported fonts here even google fonts
@charset 'utf-8';
/** font face **/
.font-a { }

@font-face {
  font-family: 'HKNova';
  src: url('font/HKNova-Medium.woff2') format('woff2'),
      url('font/HKNova-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {font-family: "RocGrotesk";
  src: url("font/a0faafb86752ddff4c3ec18939c68830.eot"); /* IE9*/
  src: url("font/a0faafb86752ddff4c3ec18939c68830.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("font/a0faafb86752ddff4c3ec18939c68830.woff2") format("woff2"), /* chrome、firefox */
  url("font/a0faafb86752ddff4c3ec18939c68830.woff") format("woff"), /* chrome、firefox */
  url("font/a0faafb86752ddff4c3ec18939c68830.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("font/a0faafb86752ddff4c3ec18939c68830.svg#Roc Grotesk W05 Wide") format("svg"); /* iOS 4.1- */
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');


/** font face **/
