
.sticky-lang {

  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  padding: 30px 5px;
  z-index: 2;

  .lang {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rotate {

    padding: 5px 10px;
    margin-top: 5px;
    @include transform(rotate(90deg));
  }

  &.white {

    background: $gray-10;
    border-right: 1px solid $gray;
  }

  a {

    display: inline-block;
    padding: 10px 5px;    
    border-radius: 20px;
    color: $black;

    &.active {

      box-shadow: 0 0 0 1px $gray;
    }
  }

  .lang-container {

    margin-top: auto;
  }

  @include media-breakpoint-down(lg) {

    display: none;
    width: auto;
    padding-left: 20px;
    padding-bottom: 20px;

    .vertical-lr {

      writing-mode: inherit;
    }

    a {

      padding: 5px 12px;
    }
  }

}