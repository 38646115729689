.border-list {

  font-size: rem(14px);
  padding: 30px 0;
  border-bottom: 1px solid $gray;

  span {

    display: block;
    margin-bottom: 5px;

    &:first-child {

      font-family: $RocGrotesk;
    }
    
  }

  &:last-child {

    margin-bottom: 30px;
  }

  a {

    color: $black;
  }
}

.contact-bottom {

  span {

    display: block;
    margin-bottom: 5px;
  }
}

.sns {

  font-size: rem(25px);
  
  a {
    color: $black;
    margin-right: 20px;
  }
}