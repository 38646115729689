.layout-backdrop {

  &[aria-hidden="true"] {

    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}