.card {

  &.card-plain {

    background: none;
    border-radius: 0;
    border: 0;
  }
}

.card-list {

  max-width: 650px;
  margin: 0 auto;

  > h4 {

    color: $orange;
  }

  + p {

    margin-top: 50px;
  }
}