.events-list {

  padding-top: 40px;

  &:last-child {

    border: none;
  }

  .events-list-inner {

    padding-bottom: 50px;
  }
}