:lang(tc) .st-nav-name {

  font-weight: bold;  
  
}

:lang(tc) {

  .sticky-nav {

    font-family: $NotoSansSC;

    .st-navs {

      width: auto;
      padding: 30px 9px;
    }

    .st-nav-name {

      writing-mode: initial;
      @include transform(rotate(90deg));
    }
  }

  .st-content {

    h2 {

      font-family: $NotoSansSC;
    }
  }

}

.sticky-nav {

  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 2;

  .st-nav-list {

    display: flex;
    height: 100%;
    border-left: 1px solid $gray;
  }

  .st-navs {

    font-size: rem(20px);
    font-family: $RocGrotesk;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 13px;
    width: 60px;
    text-transform: uppercase;
    cursor: pointer;
    background: $gray-10;

    .st-nav-top {

      span {
        display: inline-block;
        height: 30px;
        margin: 10px 0;
        border-left: 1px solid $black;
      }
    }

    .st-nav-name {

      display: block;
    }
  }
}

.st-content {

  font-size: rem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $gray;
  color: $white;

  &.st-bg-a {

    background:$gray url('../images/page_template/st_bg_a.png') no-repeat center;
    background-size: cover;
  }

  &.st-bg-b {

    background:$gray url('../images/page_template/st_bg_b.png') no-repeat center;
    background-size: cover;
  }

  &.st-bg-c {

    background:$gray url('../images/page_template/st_bg_c.png') no-repeat center;
    background-size: cover;
  }

  .st-inner {

    min-width: 762px;

    @include media-breakpoint-down(lg) {

      min-width: 250px;
    }    
  }

  .st-info {

    max-width: 470px;
    margin: 0 auto;
    
  }

  .st-content-list {

    padding: 20px 0;
    border-bottom: 1px solid $white;

    &:last-child {

      border-bottom: 0;
    }
  }

  h2 {

    font-family: $RocGrotesk;
    font-size: rem(30px);
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  em {

    display: inline-block;
    font-family: $RocGrotesk;
    font-size: rem(14px);
    font-style: normal;
    margin-bottom: 10px;
  }
}