.video-thumb {

  width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-bottom: 56.5%;

  img {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  video, 
  iframe {
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  &.playing {

    video, 
    iframe {
      
      opacity: 1;
      z-index: 2;
    }

    img {

      z-index: -1;
    }
  }

  &:before {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background:$black url('../images/event/play-button.png') no-repeat center;
    background-size: 60px;
    z-index: 0;
    
  }

}