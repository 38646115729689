.mobile-menu {

  @include media-breakpoint-up(lg) {

    display: none;
  }
}

:lang(tc) {

  .mobile-nav {

    font-family: $NotoSansSC;
  }

  .st-content {

    h2 {

      font-family: $NotoSansSC;
    }
  }

}

.mobile-nav {

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $gray-10;
  z-index: 2;
  overflow: auto;
  @include transform(translateY(-100%));

  &.easing {
    @include transition(all 0.3s ease);    
    
  }

  &.open {

    @include transform(translateY(0));
    
  }

  @include media-breakpoint-up(lg) {

    display: none;
  }

  .mobile-nav-top {

    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray;
  }

  .mobile-close {
    
    font-size: rem(16px);
    border: none;
    background: none;
    color: $black;
  }

  .mobile-st-navs {
    
    font-family: $RocGrotesk;
    padding: 20px;
    border-bottom: 1px solid $gray;
    text-transform: uppercase;

    .st-nav-top {

      display: flex;
      align-items: center;
      font-size: rem(20px);

      span {
  
        display: inline-block;
        width: 30px;
        margin: 0 10px;
        border-top: 1px solid $black;
      }
    }

    span {

      font-size: rem(14px);
    }

  }

  .st-content {

    padding: 30px 20px;
  }
  
}

.mobile-navs {

  padding: 20px;

  ul {

    padding-left: 0;
  }

  li {

    text-align: left;
    
    a {

      font-size: rem(14px);
      text-transform: uppercase;
    }
  }
}

.mobile-lang {
  margin-top: auto;
  padding: 20px;

  ul {

    padding-left: 0;

    li {

      display: inline-block;
    }

    a {

      display: inline-block;
      padding: 5px 12px;
      border-radius: 20px;
      margin-right: 5px;
      color: $black;

      &.active {

        box-shadow: 0 0 0 1px $gray;
      }
    }
  }
}