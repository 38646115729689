// this is area is for reserved class
#main-container { 

  font-size: 16px;
  width: 100%;
  min-height: 100%;
	position: relative;
	flex-direction: column;
  display: flex;
  z-index: 1;
  padding-right: 183px;
  background: $gray-10;

  &.home {

    background:$gray-10 url('../images/home/KV.png') no-repeat;
    background-position: top right;
    background-size: cover;
  }

  @include media-breakpoint-down(lg) {

    padding-right: 0;
    background-position: 100% 40px !important;
  }

}

main {
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  z-index: 1;
  padding-left: 61px;

  @include media-breakpoint-down(lg) {

    padding-left: 0;
  }
}

.hide { position:relative; }
.ease,a,input[type="submit"],button { @include transition(all 0.5s ease); }
.ease2 { @include transition(all 0.3s ease); }
.loader { position:relative; overflow:hidden; background:#fff url('../images/page_template/loading.gif') no-repeat center; }
.auto { max-width:$maxWidth; margin-left: auto; margin-right: auto; }
.img-auto { width: 100%; height: auto; }
.img-objectfit {

  object-fit: cover;
  object-position: center;
}

.zx1 { z-index: 1; }
.zx2 { z-index: 2; }
.zx3 { z-index: 3; }
.zx4 { z-index: 4; }
.zx5 { z-index: 5; }
.zx6 { z-index: 6; }
.overflow { overflow: hidden; }
.pl-0 { padding-left: 0; }
.pr-0 { padding-right: 0; }

.preloader { position:fixed; width:100%; height:100%; overflow:hidden; }
.explorer .preloader { background:#fff url('../images/page_template/loading.gif') no-repeat center; }
.explorer .preloader .spinner { display:none; }
// this is area is for reserved class

// you can include here the rest of your class name eg. home page

p {

  color: $gray;
}

.pt-10 {

  padding-top: 100px !important;

  @include media-breakpoint-down(lg) {

    padding-top: 0 !important;
  }
}

.pb-10 {

  padding-bottom: 120px !important;
}

.mt-10 {

  margin-top: 100px !important;
}

.mb-10 {

  margin-bottom: 100px !important;
}

.vertical-lr {

  writing-mode: vertical-rl;
}

.main-content {

  border: 1px solid red;
}

.logo {

  padding-top: 70px;
  padding-left: 20px;

  @include media-breakpoint-down(md) {

    width: 200px;
  }
}

.partners {

  display: flex;
  padding-top: 70px;
  padding-left: 20px;

  .partners-logo {

    margin-right: 40px;
  }
}

.btn {

  font-family: $RocGrotesk;
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;

  &.btn-outline {

    background: none;
    border: 1px solid $white;
    color: $white;

    &:hover {
      
      border-color: $orange;
      background: $orange;
    }
  }

  &.btn-wide {

    min-width: 200px;
  }

  &.btn-gray {

    color: $gray;
    border-color: $gray;

    &:hover {
      
      color: $white;
    }
  }
}

.container-sm-left {

  max-width: 1000px;  
  padding-right: 150px;

  &.container-full {

    max-width: 100%;
    padding-right: 0;

    .exhibit-lists {

      max-width: 1000px;
      width: 100%;
    }

    .exhibits {

      margin-left: -3rem;
      padding-left: 3rem;
      padding-right: 150px;
    }
  }

  @include media-breakpoint-down(lg) {

    max-width: 100%;
    padding-right: 0;

    &.container-full {
  
      .exhibit-lists {
  
        max-width: 100%;
      }
  
      .exhibits {
  
        padding-right: 0;
        margin-right: -20px;
        padding-right: 20px;
      }
    }
  }
}

.container-flex {

  display: flex;

  .inner-page {

    min-width: 55%;
    max-width: 55%;
    border-right: 1px solid $gray;
  }

  @include media-breakpoint-down(lg) {

    flex-direction: column;

    .inner-page {

      min-width: 100%;
      max-width: 100%;
      border-right: none;
    }
  }
}

.inner-page {

  padding: 100px 0 250px;
  padding-left: rem(50px);

  @include media-breakpoint-down(lg) {

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }
}

.content-title {

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: rem(30px);

  @include media-breakpoint-down(sm) {

    flex-direction: column;
    align-items: flex-start;

    .content-text {

      margin-bottom: 20px;
    }
  }
}

.collapse {

  position: relative;
  overflow: hidden;
  @include transition(height 0.3s ease);
}

.fixed-bg {

  position: fixed;
  bottom: 0;
  left: 60px;
  z-index: 3;

  &.right {

    left: auto;
    right: 180px;
  }

  @include media-breakpoint-down(lg) {

    display: none;

    &.right {

      right: 0;
    }
  }
}

.back  {

  margin-top: -50px;

  a {

    text-decoration: underline;
    color: $gray;
  }

  @include media-breakpoint-down(lg) {

    margin-top: 0;
  }
}

.highlight-info {

  color: $orange;
  
  span {

    display: block;
    font-size: rem(13px);
  }
}

.list {

  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {

    margin-right: 50px;
    margin-top: 30px;
    margin-left: 0;
  }
}

.container-md-left {

  ul {

    padding-left: 20px;

    li {

      list-style: disc;
      margin-bottom: 10px;
    }
  }
}
