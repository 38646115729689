.st-content {

  padding: 0;
  @include transition(width 0.3s ease);
  overflow: hidden;

  &:not(.open) {
    display: none;
  }
}

.mobile-nav {

  .st-content {
  
    padding: 0;
    @include transition(height 0.3s ease);
    overflow: hidden;
  
    &:not(.open) {
      display: none;
    }
  }
}
