/** CSS for pace preloader **/
body:before,body:after { @include transition(opacity .4s ease-in-out); }
// use the commented out code below if you want to cover the whole page upon load
// z-index: 9999; position: fixed; top:0; left:0; width:100%; height:100%;
.pace {
  @include pointer-events(none);
  @include user-select(none);
  @include transition(all 0.5s ease);
  background:$white;
}
.pace-inactive {
  @include opacity(0);
}
.pace .pace-progress {
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
  background: $siteColor;
  opacity: 0.5
}
/** CSS for pace preloader **/
