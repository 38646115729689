// ionclude all your related style here in the header, you can leave it empty if the page has no header
html, body { height:100%; min-height:100%;}
header {

  position: fixed;
  right: 183px;
  top: 0;
  z-index: 3;

  @include media-breakpoint-down(lg) {

    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;
  }

  nav {

    padding: 20px;
    padding-bottom: 10px;
  }

}

nav {

  ul {

    padding-left: 0;
  }

  li {

    font-family: $RocGrotesk;
    text-align: right;
    margin-bottom: 10px;

    a {

      text-transform: uppercase;
      color: $gray;
    }
  }
}

:lang(tc) {

  nav {

    li {
  
      font-family: $NotoSansSC !important;
    }
  }
  
}

.exhibit-menu {

  width: calc(48.3% - 183px);
  background: $gray-10;

  @include media-breakpoint-down(lg) {

    width: auto;
    background: none;
  }
}

