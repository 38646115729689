// ionclude all your related style here in the footer, you can leave it empty if the page has no footer
footer { 

  font-size: 10px;
  z-index: 2; 
  position: relative; 
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;

}
